<template>
  <div class="TollRoads__Import__View">
    <div class="TollRoads__Import__View__Steps">
      <section>
        <b-steps v-model="activeStep">
            <b-step-item
              :label="'בחירת קובץ'">
              <div class="ChooseFile__Step">
                  <b-field label="בחר קובץ עבור כביש האגרה הרצוי">
                    <b-field class="file is-primary"  :class="{'has-name': !!file}">
                        <b-upload v-model="file" class="file-label" rounded>
                            <span class="file-cta">
                                <b-icon class="file-icon" icon="upload"></b-icon>
                                <span class="file-label">בחר קובץ...</span>
                            </span>
                            <span class="file-name" v-if="file">
                                {{ file.name }}
                            </span>
                        </b-upload>
                    </b-field>
                </b-field>
              </div>
            </b-step-item>
             <b-step-item :clickable="false"
              :label="'הכנת מידע'">
              <div class="Progress__Step">
                <b-progress type="is-primary"
                  :value="progress" show-value format="percent"></b-progress>
              </div>
            </b-step-item>
             <b-step-item
              :label="'סטטוס'">
               <b-table
                  :data="data"
                  :paginated="true"
                  :per-page="perPage"
                  :pagination-simple="true"
                  :sticky-header="true"
                  :current-page.sync="currentPage">

                  <b-table-column label="מספר רכב" width="40"  numeric v-slot="props">
                      {{ props.row.Data[0] }}
                  </b-table-column>

                  <b-table-column field="user.first_name" label="מועד כניסה"
                     v-slot="props">
                      <span dir="ltr">{{ props.row.Data[1] }}</span>
                  </b-table-column>

                  <b-table-column field="user.last_name" label="סוג רכב"  v-slot="props">
                      {{ props.row.Data[2] }}
                  </b-table-column>

                  <b-table-column field="date" label="כניסה"  centered v-slot="props">
                      {{props.row.Data[3]}}
                  </b-table-column>

                  <b-table-column label="יציאה" v-slot="props">
                      {{props.row.Data[4]}}
                  </b-table-column>

                  <b-table-column field="date" label="מספר מקטעים"  centered v-slot="props">
                      {{props.row.Data[5]}}
                  </b-table-column>

                   <b-table-column field="date" label='סכום ללא מע"מ'  centered v-slot="props">
                      <span dir="ltr">₪{{props.row.Data[6]}}</span>
                  </b-table-column>

                  <b-table-column field="date" label='מספר חשבונית'  centered v-slot="props">
                      {{props.row.Data[7]}}
                  </b-table-column>

                  <b-table-column field="date" label='תאריך אימות'  centered v-slot="props">
                      <span dir="ltr">{{ props.row.Data[8] }}</span>
                  </b-table-column>

                  <b-table-column field="date" label='סטטוס ייבוא'  centered v-slot="props">
                      <div v-if="props.row.Errors.length" style="color: red">
                        {{props.row.Errors[0]}}
                        {{props.row.Errors[1]}}
                        {{props.row.Errors[2]}}
                      </div>
                      <div v-if="props.row.Succeeded" style="color: green">
                        הושלם
                      </div>
                  </b-table-column>
              </b-table>
            </b-step-item>

            <template
                #navigation="{previous, next}">
                <div class="TollRoads__Import__View__Steps__Navigations">
                  <b-button
                    outlined
                    type="is-primary"
                    icon=""
                    :disabled="previous.disabled"
                    @click.prevent="onPrevious(previous)">
                    חזור
                  </b-button>
                  <b-button
                      outlined
                      type="is-primary"
                      icon=""
                      :disabled="next.disabled"
                      @click.prevent="onNext(next)">
                      המשך
                  </b-button>
                </div>
            </template>
        </b-steps>
      </section>
    </div>
  </div>
</template>
<script>
import FilesService from '@/services/FilesService';
import TollRoadService from '@/services/TollRoadService';

export default {
  name: 'TollRoadsImportation',
  data() {
    return {
        activeStep: 0,
        file: null,
        guid: null,
        randomProgressRef: null,
        progress: 0,
        data: [],
        currentPage: 1,
        perPage: 30,
    };
  },
  computed: {
  },
  methods: {
    async onNext() {
      if (this.activeStep === 0 && this.file) {
        this.uploadFile();
        this.activeStep += 1;
        await this.pingResponse();
        this.activeStep += 1;
        this.progress = 0;
      }
    },
    onPrevious() {
      if (this.activeStep === 2) {
        this.activeStep = 0;
      }
    },
    randomProgress() {
      this.randomProgressRef = setInterval(() => {
        this.progress += 1;
      }, 200);
    },
    cancelRandomProgress() {
      clearInterval(this.randomProgressRef);
      return new Promise((resolve) => {
          const timer = setInterval(() => {
            this.progress += 20;
            this.progress = Math.min(100, this.progress);
            if (this.progress >= 100) {
              clearTimeout(timer);
              resolve();
            }
      }, 200);
      });
    },
    async uploadFile() {
      const formData = new FormData();
      formData.append('file', this.file);
      const file = await FilesService.uploadTollRoadExcel(formData, {
        type: 1,
        filename: 'name',
        comment: 'comment',
      });
      this.guid = file.data;
    },
    pingResponse() {
      this.randomProgress();
      return new Promise((resolve) => {
        const timeout = setInterval(async () => {
          const r = await TollRoadService.getUploadStatus(this.guid);
          if (r.data.Id === 2) {
                clearInterval(timeout);
                const content = await TollRoadService.getBatch(this.guid);
                this.data = content.data.Rows;
                await this.cancelRandomProgress();
                setTimeout(() => {
                  resolve();
                }, 500);
          }
        }, 3000);
      });
    },
  },
};
</script>
<style scoped lang="scss">
  .TollRoads__Import__View__Steps {
    padding: 50px 20px 20px 20px;
    display: flex;
    align-content: center;

    ::v-deep .step-content {
      padding: 60px 0 10px 0;
    }

    section {
      width: 100%;
    }

    .ChooseFile__Step {
      display: flex;
      flex-direction: column;
      justify-items: center;
      padding: 30px 0;
      width: 600px;
      margin: 0 auto;

      ::v-deep {
        .field, .file {
          text-align: center;
          justify-content: center;
        }
      }
    }
    .Progress__Step {
      width: 600px;
      padding: 30px 0;
      margin: 0 auto;
    }

    ::v-deep {
      .b-steps .steps .step-items .step-item:not(:first-child)::before,
      .b-steps .steps .step-items .step-item:only-child::before {
          right: -50%;
          left: unset;
      }
      .b-steps .steps .step-items .step-item::before,
      .b-steps .steps .step-items .step-item::after {
        background-position: left bottom;
        background: linear-gradient(to right , #dbdbdb 50%, #7957d5 50%);
        background-size: 200% 100%;
      }
      .b-steps .steps .step-items .step-item.is-active::before,
      .b-steps .steps .step-items .step-item.is-active::after {
        background-position: right bottom;
      }
      .b-steps .steps .step-items .step-item.is-previous::before,
      .b-steps .steps .step-items .step-item.is-previous::after {
        background-position: right bottom;
      }
    }

    .TollRoads__Import__View__Steps__Navigations {
      display: flex;
      justify-content: center;
      ::v-deep button {
        margin: 0 5px;
      }
    }
  }
  ::v-deep {
    .top.level {
        flex-direction: row-reverse;
    }
    .pagination.is-simple {
      flex-direction: row-reverse;
    }
    .b-table .table-wrapper.has-sticky-header {
      height: calc(100vh - 350px);
      overflow-y: auto;
    }
  }
</style>
