var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "TransferEntityDetails__Component" }, [
    _c("div", { staticClass: "field" }, [
      _c("span", [_vm._v("מזהה הסבה:")]),
      _c("span", [_vm._v(_vm._s(_vm.model.Id))])
    ]),
    _c("div", { staticClass: "field" }, [
      _c("span", [_vm._v("זמן יצירת הסבה:")]),
      _c("span", [_vm._v(_vm._s(_vm._f("datetime")(_vm.model.Time)))])
    ]),
    _c("div", { staticClass: "field" }, [
      _c("span", [_vm._v("הסבה מוכנה לשליחה:")]),
      _c("span", [_vm._v(_vm._s(_vm.model.IsGood ? "כן" : "לא"))])
    ]),
    _vm.model.ErrorMessage
      ? _c("div", { staticClass: "field" }, [
          _c("span", [_vm._v("שגיאת הסבה:")]),
          _c("span", [_vm._v(_vm._s(_vm.model.ErrorMessage))])
        ])
      : _vm._e(),
    _vm.model.PdfUrl
      ? _c("div", { staticClass: "field download" }, [
          _c("span", [_vm._v("קובץ הסבה:")]),
          _c(
            "span",
            [
              _c(
                "b-button",
                {
                  attrs: { loading: _vm.isLoading },
                  on: {
                    click: function($event) {
                      return _vm.download(_vm.model.PdfUrl)
                    }
                  }
                },
                [_vm._v(" הורדת קובץ ")]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }