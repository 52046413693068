<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs">
      <template #title>נסיעות בכבישי אגרה</template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import TollRoadReportsService from "@/services/TollRoadReportsService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import { saveAs } from "file-saver";

export default {
  name: "TollRoads",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
  },
  created() {
    const filters = FilterService.list();
    const { DateSearchTypes } = FilterService;
    const tollRoadsFields = FieldService.list("tollRoads");

    const excelExport = [
      {
        title: "יצוא טבלה",
        action: this.export,
      },
      {
        title: "יצוא מקובץ לפי נהגים/לקוחות",
        action: this.exportGrouped,
      },
    ];
    const fields = [
      { ...tollRoadsFields.AccountNumber, checked: true },
      { ...tollRoadsFields.VehicleType, checked: true },
      { ...tollRoadsFields.InvoiceNumber, checked: true },
      { ...tollRoadsFields.PlateNumber, checked: true },
      { ...tollRoadsFields.LinkedClient, checked: true },
      { ...tollRoadsFields.DateTaken, checked: true },
      { ...tollRoadsFields.Amount, checked: true },
      { ...tollRoadsFields.EntryPoint, checked: true },
      { ...tollRoadsFields.ExitPoint, checked: true },
      { ...tollRoadsFields.SegmentCount, checked: true },
      { ...tollRoadsFields.TollRoadStatus, checked: true },
      { ...tollRoadsFields.VerificationDate, checked: false },
      { ...tollRoadsFields.LinkedDriver, checked: false },
    ];

    this.tabs = [
      {
        id: "TollRoadDrives",
        title: "פירוט נסיעות",
        getDataAsync: this.getAllReports,
        perPage: 20,
        fields,
        filters: [
          { ...filters.TollRoadStatus },
          { ...filters.TollRoadType },
          { ...filters.PlateNumber },
          { ...filters.AccountNumber },
          { ...filters.InvoiceNumber },
          { ...filters.Profile },
          { ...filters.Driver },
          {
            ...filters.DateTypes,
            Options: [{ ...DateSearchTypes.DateTaken, Text: "תאריך נסיעה" }],
          },
        ],
        sortField: "DateTaken",
        sortOrder: "desc",
        checkableRows: true,
        excelExport,
        tableActions: [TableActions.tollRoadStatus],
      },
    ];
  },
  methods: {
    export({ loading, query }) {
      loading(true);
      TollRoadReportsService.exportXsl(query, false)
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway");
        })
        .finally(() => {
          loading(false);
        });
    },
    exportGrouped({ loading, query }) {
      loading(true);
      TollRoadReportsService.exportXsl(query, true)
        .then((r) => {
          this.saveFile(
            r.data,
            "יצוא דוחות ממערכת Betterway מקובץ לפי נהגים-לקוחות"
          );
        })
        .catch(() => {})
        .finally(() => {
          loading(false);
        });
    },
    exportByClient({ loading, query }) {
      loading(true);
      TollRoadReportsService.exportXsl(query, "Profiles")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי לקוחות");
        })
        .catch(() => {})
        .finally(() => {
          loading(false);
        });
    },
    getAllReports({ cancelToken, ...rest }) {
      return TollRoadReportsService.search(
        {
          ...rest,
        },
        cancelToken.token
      );
    },
    getPayments({ cancelToken }) {
      return TollRoadReportsService.getPayments(cancelToken.token).then(
        (r) => ({
          data: {
            TotalResults: r.data.length,
            Items: r.data,
          },
        })
      );
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
  },
};
</script>
