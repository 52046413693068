var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Report__Entity" },
    [
      _c("Entity", {
        ref: "entity",
        attrs: {
          "entity-type": "Transfer",
          "entity-id": _vm.entityId,
          innerComponent: _vm.innerComponent,
          innerComponentTitle: "פרטי הסבה",
          title: "הסבת דוח לחברה",
          "back-button-text": "רשימת הסבות לחברות",
          "back-button-url": "transfers",
          widgets: _vm.widgets,
          preview: _vm.preview,
          "read-only": _vm.readOnly,
          entityReady: _vm.entityReady,
          activities: []
        },
        on: { onFormSave: _vm.onFormSave, onFormDiscard: _vm.onFormDiscard },
        scopedSlots: _vm._u([
          {
            key: "mandatory",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("מזהה הסבה:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.Id))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [
                      _vm._v("זמן יצירת ההסבה:")
                    ]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm._f("datetime")(_vm.Time)))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [
                      _vm._v("מוכנה לשליחה:")
                    ]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.IsGood ? "כן" : "לא"))]
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }