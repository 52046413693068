<template>
    <div class="TransferEntityDetails__Component">
      <div class="field">
        <span>מזהה הסבה:</span>
        <span>{{model.Id}}</span>
      </div>
      <div class="field">
        <span>זמן יצירת הסבה:</span>
        <span>{{model.Time | datetime}}</span>
      </div>
      <div class="field">
        <span>הסבה מוכנה לשליחה:</span>
        <span>{{model.IsGood ? 'כן' : 'לא'}}</span>
      </div>
      <div class="field" v-if="model.ErrorMessage">
        <span>שגיאת הסבה:</span>
        <span>{{model.ErrorMessage}}</span>
      </div>
      <div class="field download" v-if="model.PdfUrl">
        <span>קובץ הסבה:</span>
        <span>
          <b-button :loading="isLoading" @click="download(model.PdfUrl)">
            הורדת קובץ
          </b-button>
        </span>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { saveAs } from 'file-saver';
import LeasingCompaniesService from '@/services/LeasingCompaniesService';

export default {
  name: 'TransferEntityDetails',
  data() {
    return {
      model: {},
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('transfer', [
      'form',
    ]),
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    download(url) {
      this.isLoading = true;
      LeasingCompaniesService.downloadTransfer(url)
      .then((r) => {
        if (r.data) {
          const blob = new Blob([r.data], { type: 'application/pdf' });
          saveAs(blob, 'הסבה.pdf');
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.TransferEntityDetails__Component {
  .field {
    span:first-of-type {
      font-weight: 600;
      margin-inline-end: 5px;
    }
  }
  .download {
    display: flex;
    button {
      border: none;
      padding: 0;
      margin: 0;
      height: 20px;
      font-size: 14px;
      color: #57B7CA;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
      &:active,&:focus {
        box-shadow: none;
      }
    }
  }
}
</style>
