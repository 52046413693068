var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Reports__View" },
    [
      _c("EntityListLayout", {
        attrs: { tabs: _vm.tabs },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v("הסבות לנהגים")]
            },
            proxy: true
          },
          {
            key: "counter",
            fn: function(slotProps) {
              return [
                _vm._v(' סה"כ '),
                _c("b", [_vm._v(_vm._s(_vm.getTotalRecords(slotProps)))])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }