<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs">
      <template #title>הסבות לנהגים</template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }}</b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import ReportService from "@/services/ReportService";
import EntityListLayout from "@/components/EntityListLayout.vue";
// import ReportEntityDetails from "@/components/ReportEntityDetails.vue";
import ReportEntity from "@/views/ReportEntity.vue";
import TransferDriverEntity from "@/views/TransferDriverEntity.vue";
import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import { saveAs } from "file-saver";

export default {
  name: "TransfersDrivers",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
    profileId() {
      return this.$store.state.auth.profileId;
    },
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
  },
  created() {
    const reportsFields = FieldService.list("reports");
    const reportsfilters = FilterService.list();

    const sideViewEntity = {
      component: TransferDriverEntity,
      key: "DriverTransferBatchItemId",
      recordUrl: "transfersDriver",
      preview: true,
      readOnly: true,
      title: "פרטי הסבה",
    };
    const excelExport = [
      {
        title: "יצוא טבלה",
        action: this.export,
      },
      {
        title: "יצוא מקובץ לפי עיריות",
        action: this.exportByMunic,
      },
      {
        title: "יצוא מקובץ לפי לקוח",
        action: this.exportByClient,
      },
    ];
    const fields = [
      { ...reportsFields.Address, checked: false },
      { ...reportsFields.Alerts, checked: false },
      { ...reportsFields.ApprovalDate, checked: false },
      { ...reportsFields.ClientComment, checked: false },
      { ...reportsFields.CreateDate, checked: false },
      { ...reportsFields.CurrentAmount, checked: true },
      { ...reportsFields.DateTaken, checked: true },
      { ...reportsFields.Driver, checked: false },
      { ...reportsFields.DriverAmount, checked: false },
      { ...reportsFields.DriverTransferCompleteDate, checked: true },
      { ...reportsFields.DriverTransferError, checked: true },
      { ...reportsFields.DriverTransferPdfUrl, checked: true },
      { ...reportsFields.DriverTransferSignedDate, checked: true },
      { ...reportsFields.DriverTransferStatus, checked: true },
      { ...reportsFields.DriverTransferredDate, checked: true },
      { ...reportsFields.HasScreenshot, checked: false },
      { ...reportsFields.IsPaid, checked: false },
      { ...reportsFields.IsPaidByDriver, checked: false },
      { ...reportsFields.LastPayDate, checked: true },
      { ...reportsFields.ManagerComment, checked: false },
      { ...reportsFields.MuniIdNumber, checked: false },
      { ...reportsFields.MuniReferenceNumber, checked: false },
      { ...reportsFields.Municipality, checked: true },
      { ...reportsFields.OriginalAmount, checked: true },
      { ...reportsFields.TransferredFromName, checked: false },
      { ...reportsFields.PartialPaidAmount, checked: false },
      { ...reportsFields.PaymentDate, checked: false },
      { ...reportsFields.PaymentReferenceNumber, checked: false },
      { ...reportsFields.PaymentUrl, checked: true },
      { ...reportsFields.PlateNumber, checked: true },
      { ...reportsFields.Profile, checked: false },
      { ...reportsFields.Reason, checked: false },
      { ...reportsFields.RelevantLaw, checked: false },
      { ...reportsFields.ReportCause, checked: false },
      { ...reportsFields.ReportNumber, checked: true },
      { ...reportsFields.Status, checked: true },
      { ...reportsFields.VehicleType, checked: true },
      { ...reportsFields.VerificationDate, checked: false },
    ];

    this.tabs = [
      {
        id: "TransfersDriverMissingDocumentReports",
        title: "הפקת מסמכי הסבה",
        isPrimary: true,
        getDataAsync: this.getMissingDocumentReports,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.ReportStatus },
          { ...reportsfilters.NotReportStatus },
          { ...reportsfilters.Reason },
          { ...reportsfilters.Alerts },
          { ...reportsfilters.VehicleType },

          {
            ...reportsfilters.Profile,
            isHidden: !this.isLeasingCompany,
          },
          { ...reportsfilters.Driver },
          { ...reportsfilters.PlateNumber },
          { ...reportsfilters.MuniIdNumber },
          { ...reportsfilters.Municipality },
          {
            ...reportsfilters.DriverTransferStatusWNotTransferred,
            selected: [0, 14],
          },
          { ...reportsfilters.Other },
          { ...reportsfilters.DateTypes },
        ],
        sortField: "DriverTransferError",
        sortOrder: "desc",
        checkableRows: true,
        fastSearch: true,
        sideViewEntity: {
          component: ReportEntity,
          preview: true,
          readOnly: true,
          title: "פרטי דוח",
          recordUrl: "reports",
        },
        excelExport,
        tableActions: [
          TableActions.createTransfersDriver,
          TableActions.driverAssign,
          TableActions.markAsPaid,
          TableActions.addTask,
        ],
      },

      {
        id: "TransfersDriverSignByLawyerPending",
        title: 'ממתינים לחתימת עו"ד',
        getDataAsync: this.getTransfersViaLawyer,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.NotReportStatus },
          { ...reportsfilters.DriverTransferStatus, selected: [12] },
          { ...reportsfilters.MuniIdNumber },
          { ...reportsfilters.Municipality },
        ],
        sortField: "Id",
        sortOrder: "desc",
        checkableRows: true,
        fastSearch: true,
        sideViewEntity,
        tableActions: (() => {
          let actions = [TableActions.signOnReports, TableActions.addTask];
          if (this.isAdmin) {
            actions = actions.concat([TableActions.changeTransferStatusDriver]);
          }
          return actions;
        })(),
      },
      {
        id: "TransfersDriverViaMail",
        title: "ממתינים לשליחה בדואר",
        getDataAsync: this.getTransfersViaMail,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.ReportStatus },
          { ...reportsfilters.NotReportStatus, selected: [0] },
          { ...reportsfilters.Reason },
          { ...reportsfilters.Alerts },
          {
            ...reportsfilters.Profile,
            isHidden: !this.isLeasingCompany,
          },
          { ...reportsfilters.Driver },
          { ...reportsfilters.DriverTransferStatus, selected: [4, 11] },
          { ...reportsfilters.ReportNumber },
          { ...reportsfilters.MuniIdNumber },
          { ...reportsfilters.PlateNumber },
          { ...reportsfilters.Municipality },
          { ...reportsfilters.DateTypes },
        ],
        sortField: "DateTaken",
        sortOrder: "desc",
        checkableRows: true,
        excelExport,
        fastSearch: true,
        sideViewEntity,
        tableActions: [
          TableActions.driverPostRegisteredMail,
          TableActions.printDriverTransfers,
          TableActions.markAsPaid,
          TableActions.addTask,
        ],
      },
      {
        id: "TransfersDriverHandling",
        title: "ניהול מסמכי הסבה",
        getDataAsync: this.getReportsForHandle,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.ReportStatus },
          { ...reportsfilters.NotReportStatus },
          { ...reportsfilters.Reason },
          { ...reportsfilters.Alerts },
          {
            ...reportsfilters.Profile,
            isHidden: !this.isLeasingCompany,
          },
          { ...reportsfilters.Driver },
          {
            ...reportsfilters.DriverTransferStatus,
            selected: [4, 6, 11, 12, 14, 17],
          },
          { ...reportsfilters.ReportNumber },
          { ...reportsfilters.MuniIdNumber },
          { ...reportsfilters.PlateNumber },
          { ...reportsfilters.Municipality },
          { ...reportsfilters.DateTypes },
        ],
        sortField: "Id",
        sortOrder: "desc",
        checkableRows: true,
        fastSearch: true,
        sideViewEntity,
        excelExport,
        tableActions: (() => {
          let actions = [
            TableActions.driverPostRegisteredMail,
            TableActions.printDriverTransfers,
            TableActions.markAsPaid,
            TableActions.addTask,
          ];
          if (this.isAdmin) {
            actions = actions.concat([TableActions.changeTransferStatusDriver]);
          }
          return actions;
        })(),
      },
      {
        id: "TransfersDriverMissingDocumentReportsPrivate",
        title: "הפקת מסמכי הסבה- פרטי",
        isPrimary: true,
        getDataAsync: this.getMissingDocumentReports,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.ReportStatus },
          { ...reportsfilters.NotReportStatus },
          { ...reportsfilters.Reason },
          { ...reportsfilters.MuniIdNumber },
          { ...reportsfilters.Alerts },
          { ...reportsfilters.VehicleType, selected: 2 },

          {
            ...reportsfilters.Profile,
            isHidden: !this.isLeasingCompany,
          },
          { ...reportsfilters.Driver },
          { ...reportsfilters.PlateNumber },
          { ...reportsfilters.Municipality },
          {
            ...reportsfilters.DriverTransferStatusWNotTransferred,
            selected: [0, 14],
          },
          { ...reportsfilters.Other },
          { ...reportsfilters.DateTypes },
        ],
        sortField: "DriverTransferError",
        sortOrder: "desc",
        checkableRows: true,
        fastSearch: true,
        sideViewEntity: {
          component: ReportEntity,
          preview: true,
          readOnly: true,
          title: "פרטי דוח",
          recordUrl: "reports",
        },
        excelExport,
        tableActions: [
          TableActions.createTransfersDriver,
          TableActions.driverAssign,
          TableActions.markAsPaid,
          TableActions.addTask,
        ],
      },
      {
        id: "TransfersDriverMissingDocumentReportsCommercial",
        title: "הפקת מסמכי הסבה- מסחרי",
        isPrimary: true,
        getDataAsync: this.getMissingDocumentReports,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.ReportStatus },
          { ...reportsfilters.NotReportStatus },
          { ...reportsfilters.Reason },
          { ...reportsfilters.Alerts },
          { ...reportsfilters.VehicleType, selected: 1 },

          {
            ...reportsfilters.Profile,
            isHidden: !this.isLeasingCompany,
          },
          { ...reportsfilters.Driver },
          { ...reportsfilters.PlateNumber },
          { ...reportsfilters.Municipality },
          { ...reportsfilters.MuniIdNumber },
          {
            ...reportsfilters.DriverTransferStatusWNotTransferred,
            selected: [0, 14],
          },
          { ...reportsfilters.Other },
          { ...reportsfilters.DateTypes },
        ],
        sortField: "DriverTransferError",
        sortOrder: "desc",
        checkableRows: true,
        fastSearch: true,
        sideViewEntity: {
          component: ReportEntity,
          preview: true,
          readOnly: true,
          title: "פרטי דוח",
          recordUrl: "reports",
        },
        excelExport,
        tableActions: [
          TableActions.createTransfersDriver,
          TableActions.driverAssign,
          TableActions.markAsPaid,
          TableActions.addTask,
        ],
      },
    ];
    if (this.isAdmin) {
      this.tabs.push({
        id: "TransfersDriverClarificationStatus",
        title: "הסבות לבירור סטטוס",
        isPrimary: true,
        getDataAsync: this.getReportsForClarification,
        perPage: 20,
        fields,
        filters: [
          { ...reportsfilters.ReportStatus },
          { ...reportsfilters.NotReportStatus, selected: [0] },
          { ...reportsfilters.Reason },
          { ...reportsfilters.Alerts },
          { ...reportsfilters.Profile },
          { ...reportsfilters.Driver },
          { ...reportsfilters.ReportNumber },
          { ...reportsfilters.MuniIdNumber },
          { ...reportsfilters.PlateNumber },
          { ...reportsfilters.Municipality },
          {
            ...reportsfilters.DriverTransferStatus,
            selected: [3, 4, 5, 7, 8, 15, 19],
          },
          {
            ...reportsfilters.DateTypes,
            Options: [
              {
                ...FilterService.DateSearchTypes.TransferredDriverDate,
                preSettedDate: {
                  value: 13,
                  endDate: this.monthAdd(new Date(), -1),
                },
              },
            ],
          },
        ],
        sortField: "DriverTransferError",
        sortOrder: "desc",
        checkableRows: true,
        fastSearch: true,
        sideViewEntity: {
          component: ReportEntity,
          preview: true,
          readOnly: true,
          title: "פרטי דוח",
          recordUrl: "reports",
        },
        excelExport,
        tableActions: [
          TableActions.createTransfersDriver,
          TableActions.driverAssign,
          TableActions.markAsPaid,
          TableActions.addTask,
          {
            ...TableActions.changeTransferStatusDriver,
            Label: "שינוי סטטוס הסבת נהג",
          }
        ],
      });
    }
  },
  methods: {
    monthAdd(date, month) {
      let temp = date;
      temp = new Date(date.getFullYear(), date.getMonth(), 1);
      temp.setMonth(temp.getMonth() + (month + 1));
      temp.setDate(temp.getDate() - 1);
      if (date.getDate() < temp.getDate()) {
        temp.setDate(date.getDate());
      }
      return temp;
    },
    getTransfersViaLawyer({ cancelToken, ...rest }) {
      return ReportService.search(
        {
          ...rest,
          IsPaid: false,
          ProfileId: this.profileId,
        },
        cancelToken.token
      );
    },
    getTransfersViaMail({ cancelToken, setPermanentQuery, ...rest }) {
      const query = {
        IgnoreDoubleFineForTransfer: true,
        IsPaid: false,
        ProfileId: this.profileId,
      };
      setPermanentQuery(query);
      return ReportService.search(
        {
          ...rest,
          ...query,
        },
        cancelToken.token
      );
    },
    // getAllReports({ cancelToken, ...rest }) {
    //   return ReportService.search(
    //     {
    //       ...rest,
    //     },
    //     cancelToken.token
    //   );
    // },

    getMissingDocumentReports({ cancelToken, setPermanentQuery, ...rest }) {
      const query = {
        AlertType: "null",
        CauseIds: [],
        Causes: [],
        ProfileId: this.profileId,
        SearchTerm: "",
        SortedBy: "DriverTransferError",
        Status: [],
        SearchType: { Name: 'ת. קבלת הדו"ח', Id: "ReportDate" },
        CanBeTransferredToDriver: true,
        IgnoreDoubleFineForTransfer: true,
        IsPaid: false,
      };

      setPermanentQuery(query);

      return ReportService.search(
        {
          ...query,
          ...rest,
        },
        cancelToken.token
      );
    },
    getReportsForHandle({ cancelToken, setPermanentQuery, ...rest }) {
      const query = {
        IgnoreDoubleFineForTransfer: true,
        IsPaid: false,
        ProfileId: this.profileId,
      };

      setPermanentQuery(query);

      return ReportService.search(
        {
          ...rest,
          ...query,
        },
        cancelToken.token
      );
    },

    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total;
    },

    getReportsForClarification({ cancelToken, setPermanentQuery, ...rest }) {
      const query = rest.ToDriverTransferredDate
        ? { IsPaid: false }
        : {
            IsPaid: false,
            ToDriverTransferredDate: this.monthAdd(new Date(), -1),
          };
      query.ProfileId = this.profileId;
      setPermanentQuery(query);
      return ReportService.search(
        {
          ...rest,
          ...query,
        },
        cancelToken.token
      );
    },

    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    export({ loading, query }) {
      loading(true);
      ReportService.exportXsl(query)
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות הסבה לנהג ממערכת Betterway");
        })
        .finally(() => {
          loading(false);
        });
    },
    exportByMunic({ loading, query }) {
      loading(true);
      ReportService.exportXsl(query, "Municipalities")
        .then((r) => {
          this.saveFile(
            r.data,
            "יצוא דוחות הסבה לנהג ממערכת Betterway מקובץ לפי עיריות"
          );
        })
        .catch(() => {})
        .finally(() => {
          loading(false);
        });
    },
    exportByClient({ loading, query }) {
      loading(true);
      ReportService.exportXsl(query, "Profiles")
        .then((r) => {
          this.saveFile(
            r.data,
            "יצוא דוחות הסבה לנהג ממערכת Betterway מקובץ לפי לקוחות"
          );
        })
        .catch(() => {})
        .finally(() => {
          loading(false);
        });
    },
  },
};
</script>
