<template>
  <div class="Report__Entity">
    <Entity
      ref="entity"
      entity-type="Transfer"
      :entity-id="entityId"
      :innerComponent="innerComponent"
      innerComponentTitle="פרטי הסבה"
      title="הסבת דוח לחברה"
      back-button-text="רשימת הסבות לחברות"
      back-button-url="transfers"
      :widgets="widgets"
      :preview="preview"
      :read-only="readOnly"
      :entityReady="entityReady"
      :activities="[]"
      @onFormSave="onFormSave"
      @onFormDiscard="onFormDiscard"
    >
      <template #mandatory>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">מזהה הסבה:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Id }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">זמן יצירת ההסבה:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Time | datetime }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">מוכנה לשליחה:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ IsGood ? "כן" : "לא" }}</span
          >
        </div>
      </template>
    </Entity>
  </div>
</template>

<script>
import Entity from "@/components/Entity.vue";
import TransferEntityDetails from "@/components/TransferEntityDetails.vue";
import moment from "moment";
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import ReportService from "@/services/ReportService";
import WidgetService from "../services/WidgetService";
import VehicleService from '../services/VehicleService';

const { mapFields } = createHelpers({
  getterType: "transfer/getField",
  mutationType: "transfer/updateField",
});

export default {
  components: {
    Entity,
  },
  props: ["id", "preview", "readOnly"],
  computed: {
    entityId() {
      return this.$route.params.id || this.id;
    },
    widgets() {
      return [
        { ...WidgetService.TransferStatusesCompanyWidget, namespace: "transfer" },
        { ...WidgetService.Profile, namespace: "report" },
        { ...WidgetService.ReportDetailsWidget, namespace: "transfer" },
        {
          ...WidgetService.Vehicle,
          namespace: "report",
          fetch: true,
          delegate: VehicleService.getVehicle
        },
        // { ...WidgetService.TransferDocumentsWidget },
      ];
    },
    innerComponent() {
      return TransferEntityDetails;
    },
    ...mapFields(["Id", "IsGood", "Time"]),
  },
  filters: {
    date(value) {
      const date = moment(value, "YYYY-MM-DDThh:mm");
      return date.format("DD/MM/YYYY hh:mm");
    },
  },
  data() {
    return {
      entityReady: false,
    };
  },
  async created() {
    this.init();
  },
  methods: {
    async init(callback) {
      try {
        const transfer = await this.load({
          id: this.entityId,
        });
        await this.loadReport({
          id: transfer.Report.Id,
        });
        this.entityReady = true;
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    onFormSave({ refresh, closeDialog, model, save }) {
      save(true);
      ReportService.update(this.entityId, {
        ...this.$store.state.report,
        ...model,
      })
        .then(() => {
          this.init(refresh);
        })
        .catch(() => {
          alert("קרתה תקלה לא היה ניתן לשמור את הדוח");
          refresh();
        })
        .finally(() => {
          save(false);
          closeDialog();
        });
    },
    onFormDiscard({ restoreState, closeDialog }) {
      restoreState();
      closeDialog();
    },
    ...mapActions({
      load: "transfer/loadTransfer",
      loadReport: "report/loadReport",
    }),
  },
};
</script>

<style>
</style>
