var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "TollRoads__Import__View" }, [
    _c("div", { staticClass: "TollRoads__Import__View__Steps" }, [
      _c(
        "section",
        [
          _c(
            "b-steps",
            {
              scopedSlots: _vm._u([
                {
                  key: "navigation",
                  fn: function(ref) {
                    var previous = ref.previous
                    var next = ref.next
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "TollRoads__Import__View__Steps__Navigations"
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                outlined: "",
                                type: "is-primary",
                                icon: "",
                                disabled: previous.disabled
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onPrevious(previous)
                                }
                              }
                            },
                            [_vm._v(" חזור ")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                outlined: "",
                                type: "is-primary",
                                icon: "",
                                disabled: next.disabled
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onNext(next)
                                }
                              }
                            },
                            [_vm._v(" המשך ")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.activeStep,
                callback: function($$v) {
                  _vm.activeStep = $$v
                },
                expression: "activeStep"
              }
            },
            [
              _c("b-step-item", { attrs: { label: "בחירת קובץ" } }, [
                _c(
                  "div",
                  { staticClass: "ChooseFile__Step" },
                  [
                    _c(
                      "b-field",
                      { attrs: { label: "בחר קובץ עבור כביש האגרה הרצוי" } },
                      [
                        _c(
                          "b-field",
                          {
                            staticClass: "file is-primary",
                            class: { "has-name": !!_vm.file }
                          },
                          [
                            _c(
                              "b-upload",
                              {
                                staticClass: "file-label",
                                attrs: { rounded: "" },
                                model: {
                                  value: _vm.file,
                                  callback: function($$v) {
                                    _vm.file = $$v
                                  },
                                  expression: "file"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "file-cta" },
                                  [
                                    _c("b-icon", {
                                      staticClass: "file-icon",
                                      attrs: { icon: "upload" }
                                    }),
                                    _c("span", { staticClass: "file-label" }, [
                                      _vm._v("בחר קובץ...")
                                    ])
                                  ],
                                  1
                                ),
                                _vm.file
                                  ? _c("span", { staticClass: "file-name" }, [
                                      _vm._v(" " + _vm._s(_vm.file.name) + " ")
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "b-step-item",
                { attrs: { clickable: false, label: "הכנת מידע" } },
                [
                  _c(
                    "div",
                    { staticClass: "Progress__Step" },
                    [
                      _c("b-progress", {
                        attrs: {
                          type: "is-primary",
                          value: _vm.progress,
                          "show-value": "",
                          format: "percent"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "b-step-item",
                { attrs: { label: "סטטוס" } },
                [
                  _c(
                    "b-table",
                    {
                      attrs: {
                        data: _vm.data,
                        paginated: true,
                        "per-page": _vm.perPage,
                        "pagination-simple": true,
                        "sticky-header": true,
                        "current-page": _vm.currentPage
                      },
                      on: {
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    },
                    [
                      _c("b-table-column", {
                        attrs: { label: "מספר רכב", width: "40", numeric: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v(" " + _vm._s(props.row.Data[0]) + " ")
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: {
                          field: "user.first_name",
                          label: "מועד כניסה"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c("span", { attrs: { dir: "ltr" } }, [
                                  _vm._v(_vm._s(props.row.Data[1]))
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: { field: "user.last_name", label: "סוג רכב" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v(" " + _vm._s(props.row.Data[2]) + " ")
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: { field: "date", label: "כניסה", centered: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v(" " + _vm._s(props.row.Data[3]) + " ")
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: { label: "יציאה" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v(" " + _vm._s(props.row.Data[4]) + " ")
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: {
                          field: "date",
                          label: "מספר מקטעים",
                          centered: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v(" " + _vm._s(props.row.Data[5]) + " ")
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: {
                          field: "date",
                          label: 'סכום ללא מע"מ',
                          centered: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c("span", { attrs: { dir: "ltr" } }, [
                                  _vm._v("₪" + _vm._s(props.row.Data[6]))
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: {
                          field: "date",
                          label: "מספר חשבונית",
                          centered: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v(" " + _vm._s(props.row.Data[7]) + " ")
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: {
                          field: "date",
                          label: "תאריך אימות",
                          centered: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c("span", { attrs: { dir: "ltr" } }, [
                                  _vm._v(_vm._s(props.row.Data[8]))
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: {
                          field: "date",
                          label: "סטטוס ייבוא",
                          centered: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                props.row.Errors.length
                                  ? _c(
                                      "div",
                                      { staticStyle: { color: "red" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(props.row.Errors[0]) +
                                            " " +
                                            _vm._s(props.row.Errors[1]) +
                                            " " +
                                            _vm._s(props.row.Errors[2]) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                props.row.Succeeded
                                  ? _c(
                                      "div",
                                      { staticStyle: { color: "green" } },
                                      [_vm._v(" הושלם ")]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }